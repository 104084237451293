import { debounce } from './functions' 
import $ from '../../../config/node_modules/jquery';

// Filter suitables single product

$(document).on("click", ".single-product .suitable-for .suitable-item", filterProductsSuitable);

function filterProductsSuitable() {
    window.location.href = $(this).attr("data-link");
}

var debouncedFilter = debounce(function() {
  filterProducts();
}, 50);

// Filter projects and posts
$(document).on("click", ".filter-tags .accordion, .filter-tags .subcats .check_prep, .filter-sector .check_sector, .filter-suitable .suitable-item", function() {
  let el = $(this)[0] instanceof HTMLElement ? $(this) : $(this)[0]; 
  
  console.log(el);

  $(el).toggleClass("active");
  debouncedFilter();
});

function filterProducts() {

    // .productfilter .accordion.active
    console.log(document.getElementsByTagName('body')[0].getAttribute('post_type'))
    if (document.getElementsByTagName('body')[0].getAttribute('post_type') === 'categorie'){
      var mainCatSlugs = $('.filter-tags')
          .map(function(){return $(this).attr("data-slug")})
          .get()
          .join(",");

      // .filter-tags .check_prep:checked
      var subCatSlugs = $('.productfilter .accordion.active')
          .map(function(){return $(this).attr("data-slug")})
          .get()
          .join(",");
    } else {
      var mainCatSlugs = $('.main-category-selector.active')
          .map(function(){return $(this).attr("data-slug")})
          .get()
          .join(",");

      var subCatSlugs = $('.subcats .check_prep:checked')
        .map(function(){return $(this).attr("data-slug")})
        .get()
        .join(",");
    }

    var sector = $('.filter-sector .check_sector:checked')
        .map(function(){return $(this).attr("data-slug")})
        .get()
        .join(",");


    var suitable = $('.filter-suitable .suitable-item.active')
        .map(function(){return $(this).attr("data-suitable")})
        .get()
        .join(",");


    var get_url = "/json-categories?categories="+mainCatSlugs+"&subcategories="+subCatSlugs+"&sector="+sector+"&suitable="+suitable+"&posttype="+document.getElementsByTagName('body')[0].getAttribute('post_type');

    var postsContainer = $("#products-container");

    console.log(get_url);
    console.log(suitable);
    console.log(sector);
    console.log(mainCatSlugs);
    console.log(subCatSlugs);

    window.history.pushState(null, null, "?categories="+mainCatSlugs+"&subcategories="+subCatSlugs+"&sector="+sector+"&suitable="+suitable+"&posttype="+document.getElementsByTagName('body')[0].getAttribute('post_type'));

    $(".products-overview-container .loading-filters").show();
    $.ajax({url: get_url ,
      success: function(result){
        $(".products-overview-container .loading-filters").hide();
        $(postsContainer).html(result).hide().fadeIn(500);
        $(".none").removeClass("none");
      },
      error: function(result){
        $(".products-overview-container .loading-filters").hide();
      }});


}

// Ajaxify the pagination
$(document).on("click", ".pagination a", function(e){
  e.preventDefault();

  var stored_params = $(this).attr("href").split("?")[1];
  console.log(stored_params);
  if (stored_params.includes('&posttype=')){
    var get_url = "/json-categories?"+stored_params;
  } else {
    var get_url = "/json-categories?"+stored_params+"&posttype="+document.getElementsByTagName('body')[0].getAttribute('post_type');
  }


 
  var postsContainer = $("#products-container");

  $.ajax({url: get_url , success: function(result){
      $(postsContainer).html(result).hide().fadeIn(500);
      $('html,body').animate({
        scrollTop: $(postsContainer).offset().top
      }, 500);
    }});
});

$(function(){
  // Filter based on parameters in URL
  var param_categories = getUrlParameter('categories');
  var param_subcategories = getUrlParameter('subcategories');
  var param_sector = getUrlParameter('sector');
  var param_suitable = getUrlParameter('suitable');

  if(param_categories || param_subcategories || param_sector || param_suitable) {

    if(param_subcategories) {
      param_subcategories = param_subcategories.split(',')
    }
    if(param_suitable) {
      param_suitable = param_suitable.split(',')
    }

    $(param_categories, function(i) {
      var element = $('*[data-slug="'+param_categories+'"]');
      var value = element.attr('data-slug');

      $(element).addClass('active');


      if ($(element).next('.panel').find('.check_prep').length === 0) {
        $(element).next('.panel').css('max-height', '0');
        $(element).addClass('no-border');
      } else {
        var panel = $(element).next('.hoofdcat.panel');
        var panelHeight = $(panel).prop('scrollHeight');

        $(panel).css('max-height', panelHeight);
      }


      /*if ($(element).next('.panel').find('.check_prep').length === 0) {
        $(this).next('.panel').css('max-height', '0');
        $(this).addClass('no-border');
      }*/

    });

    $.each(param_subcategories, function(i) {
      var element = $('*[data-slug="'+param_subcategories[i]+'"]');
      var value = element.attr('data-slug');

      $(element).prop('checked', true)
    });

    $(param_sector, function(i) {
      var element = $('*[data-slug="'+param_sector+'"]');
      var value = element.attr('data-slug');

      $(element).prop('checked', true)

      if ($(window).width() < 989) {

        var panel = $(element).next('.panel');
        var panelHeight = $(panel).prop('scrollHeight');

        $(panel).css('max-height', panelHeight);

      }
    });

    $.each(param_suitable, function(i) {
      var element = $('*[data-suitable="'+param_suitable[i]+'"]');
      var value = element.attr('data-suitable');

      $(element).addClass('active');

      if ($(window).width() < 989) {

        var panel = $(element).next('.panel');
        var panelHeight = $(panel).prop('scrollHeight');

        $(panel).css('max-height', panelHeight);

      }
    });

  }
});

// Get parameters from URL
function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    } else {
    }
  }
}

