// import '../../../config/node_modules/flickity/dist/flickity.pkgd';
// import '../../../config/node_modules/flickity/dist/flickity.pkgd'

var Flickity = require('../../../config/node_modules/flickity');
var sliderContainers = document.querySelectorAll('.slider-container .slides');

sliderContainers.forEach(element => {
    var $slider = new Flickity(element, {
        autoPlay: 2000,
        pageDots: false,
        prevNextButtons: true,
        wrapAround: true,
        draggable: false,
        setGallerySize: false,
    });
});


// var $slider = $('.slider-container .slides').flickity({
//     autoPlay: 2000,
//     pageDots: false,
//     prevNextButtons: true,
//     wrapAround: true,
//     draggable: false,
//     setGallerySize: false,
// });