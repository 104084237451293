import $ from "../../../config/node_modules/jquery";
// var $ = require("../../../config/node_modules/jquery");
// import spritespin from "../../../config/node_modules/spritespin";

// import * as spritespin from "../../../config/node_modules/spritespin/release/spritespin";
import * as SpriteSpin from "../../../config/node_modules/spritespin";

// var SpriteSpin = require("../../../config/node_modules/spritespin")

console.log({ SpriteSpin });

document.addEventListener("DOMContentLoaded", (event) => {
  var productContainer = $("#product-360");
  if ($("#product-360").length) {
    rotate360(productContainer);
  }

  $(".single-product .product-header .product-image svg").on(
    "click",
    function (e) {
      e.preventDefault();
      rotate360(productContainer);
    }
  );
});

function rotate360(container) {
  const imagesUrls = $(container).data("images").split("||");

  console.log(imagesUrls);

  //   $("#product-360").spritespin({
  //     // pad naar de bronafbeeldingen.
  //     source: imagesUrls,
  //     // breedte   : 480,  // breedte in pixels van het venster/frame
  //     // hoogte  : 327,  // hoogte in pixels van het venster/frame
  //     responsive: true,
  //     sense: -0.7,
  //     animate: true,
  //     loop: false,
  //     frames: imagesUrls.length,
  //   });

  var rotate360SpriteSpin = $(container).spritespin({
    target: container,
    // target: "#product-360",
    // width: 480,
    // height: 327,
    source: imagesUrls,
    frame: 0,
    stopFrame: 35,
    lane: 0,
    responsive: true,
    sense: -0.7,
    animate: true,
    loop: false,
    frames: imagesUrls.length,
  });

  console.log({ rotate360SpriteSpin });
}
