import $ from "../../../config/node_modules/jquery";
import Swiper, {
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Controller,
  Thumbs,
  Mousewheel,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
  EffectCards,
  EffectFade,
  Parallax,
} from "../../../config/node_modules/swiper";
// Swiper.use([
//   Navigation,
//   Pagination,
//   Scrollbar,
//   Controller,
//   Lazy,
//   Mousewheel,
//   Autoplay,
//   EffectFade,
//   EffectCards,
// ]);

const homeSlider = () => {
  // $(document).ready(function () {

  if ($(".home-header .slides").length) {
    var pagination_text = $(".slides").data("slide");
    var pagination_text_link = $(".slides").data("links");
    var pagination = $(".home-header .swiper-pagination");
    // console.log(pagination_text_link);

    var one_slide = pagination_text.split("_");
    var one_link = pagination_text_link.split("|");
    console.log(one_link);
    // console.log(one_slide);
    var menu = one_slide;
    var linken = one_link;
    var mySwiper = new Swiper(".project-content", {
      modules: [Autoplay, Navigation, Pagination],
      // Optional parameters
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: pagination,
        clickable: true,
        renderBullet: function (index, className) {
          return (
            '<div class="' +
            className +
            '"><a class="full-link-btn" href="' +
            linken[index] +
            '" ></a><p>' +
            menu[index] +
            "</p></div>"
          );
        },
      },
      direction: "horizontal",
      loop: true,
    });
  }

  $(
    ".swiper-pagination:not(.schedule__week__pagination):not(.header-slider .swiper-pagination)"
  ).append('<a class="full-link-btn link" href=""></a>');
  // });
};

const headerSlider = () => {
  var headerSliders = document.querySelectorAll(
    ".slider-container-header:not(.one-slide)"
  );
  if (typeof headerSliders != "undefined" && headerSliders != null) {
    headerSliders.forEach((headerSlider) => {
      var slides = headerSlider.querySelectorAll(".swiper-slide");
      headerSlider = new Swiper(headerSlider, {
        modules: [Pagination],
        direction: "horizontal",
        speed: 800,
        roundLengths: true,
        // centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: {
          forceToAxis: true,
          thresholdTime: 400,
          thresholdDelta: 4,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        // navigation: {
        // 	nextEl: '.slider-container.images-slider .swiper-button-next',
        // 	prevEl: '.slider-container.images-slider .swiper-button-prev',
        // },
        // breakpoints: {
        // 	0: {
        // 		spaceBetween: 32,
        // 	},
        // 	767: {
        // 		spaceBetween: 32,
        // 	},
        // 	1200: {
        // 		slidesPerView: 'auto',
        // 		spaceBetween: 32,
        // 	}
        // },
      });
    });
  }
};

const gallerySlider = () => {
  var gallerySliders = document.querySelectorAll(
    ".slider-container.galerij-wrapper"
  );
  if (typeof gallerySliders != "undefined" && gallerySliders != null) {
    gallerySliders.forEach((gallerySlider) => {
      console.log(gallerySlider);
      var slides = gallerySlider.querySelectorAll(".swiper-slide");
      var prevButton = gallerySlider.parentElement.querySelector(
        ".swiper-button-prev"
      );
      var nextButton = gallerySlider.parentElement.querySelector(
        ".swiper-button-next"
      );
      var paginationEl =
        gallerySlider.parentElement.querySelector(".swiper-pagination");
      if (gallerySlider.classList.contains("cards")) {
        gallerySlider = new Swiper(gallerySlider, {
          modules: [EffectCards, EffectCreative, Navigation, Pagination],
          loop: true,
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
          loopedSlides: gallerySlider.length * 2,
          slidesPerView: 1,
          spaceBetween: 100,
          grabCursor: true,
          effect: "creative",
          creativeEffect: {
            prev: {
              shadow: false,
              translate: ["-30%", 0, -500],
            },
            next: {
              shadow: false,
              translate: ["30%", 0, -500],
            },
          },
          pagination: {
            el: paginationEl,
            clickable: true,
          },
        });
      } else if (gallerySlider.classList.contains("nav")) {
        var gallerySliderSingle = new Swiper(gallerySlider, {
          modules: [
            Autoplay,
            Parallax,
            Navigation,
            Pagination,
            EffectFade,
            Thumbs,
          ],
          loop: false,
          speed: 800,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false,
          },
          slidesPerView: 1,
          grabCursor: false,
          allowTouchMove: false,
          spaceBetween: 80,
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
          pagination: {
            el: paginationEl,
            clickable: true,
          },
        });
      } else {
        gallerySlider = new Swiper(gallerySlider, {
          direction: "horizontal",
          loop: true,
          // loopedSlides: slides,
          speed: 800,
          roundLengths: true,
          centeredSlides: true,
          spaceBetween: 30,
          mousewheel: {
            forceToAxis: true,
            thresholdTime: 400,
            thresholdDelta: 4,
          },
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
          breakpoints: {
            0: {
              slidesPerView: 1.2,
            },
            767: {
              slidesPerView: 2.2,
            },
            1200: {
              slidesPerView: 3,
            },
          },
        });
      }
    });
  }
};

export { homeSlider, headerSlider, gallerySlider };
