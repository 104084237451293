import { gsap } from "../../../config/node_modules/gsap";

export function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

let main = document.querySelector('main');
let popupTriggers = [];
let popupTriggersLanguage = [];
let defaultPopup = [];
var currentPopup;
import { copyLink } from "./functions";

async function popup() {
   
   popupTriggers = Array.from(document.querySelectorAll('.popup-trigger'));
   popupTriggers.forEach(popupTrigger => {
      var dataParent = popupTrigger.getAttribute("data-parent");
      var dataId = popupTrigger.getAttribute("data-id");
      var popup = document.querySelector(".popup[data-type=" + dataParent + "]");
      popupTrigger.addEventListener("click", e => currentPopup = new PopUp(e, popup, dataParent, dataId));

      if (popupTrigger.classList.contains('site-wide-popup')) {
         // if (getCookie('popup') != 'true') {
         // }
         // setCookie(('popup'), true, 1);
         currentPopup = new PopUp('', popup, dataParent, dataId);

         setInterval(() => {
            popupTriggersLanguage = Array.from(popup.querySelectorAll('.popup-trigger'));
            console.log(popupTriggersLanguage)
            popupTriggersLanguage.forEach(popupTriggerL => {
               
               var dataParentL = popupTriggerL.getAttribute("data-parent");
               var dataIdL = popupTriggerL.getAttribute("data-id");
               popupTriggerL.addEventListener("click", e => currentPopup = new PopUp(e, popup, dataParentL, dataIdL));
            })
         }, 700);
      }
   });
} export {
   popup
}

class PopUp {
   constructor(e, wrapper, parent, id) {
      this.DOM = {el: wrapper};
      this.DOM.selectedPopup = [];
      this.DOM.popup_inner = wrapper.querySelector('.popup__inner');
      this.DOM.popup_content = wrapper.querySelector('.popup__content')
      this.DOM.wrapper = wrapper;
      this.DOM.close = wrapper.querySelector('.popup__close');

      this.DOM.selectedPopup[parent] = id;
      this.initEvents();
      this.setPopupContent(this.DOM.selectedPopup, this.DOM.popup_content, this.DOM.wrapper);
   }
   setPopupContent(data, container, wrapper) {
      var text = [];
      var i;
      for (i in data) {
         if (data.hasOwnProperty(i)) {
            text.push(i + "=" + encodeURIComponent(data[i]));
         }
      }
      var textState = [];
      var i;
      for (i in data) {
         if (data.hasOwnProperty(i)) {
            if (i != 'page') {
               textState.push(i + "=" + encodeURIComponent(data[i]));
            }
         }
      }
      text = text.join("&");
      textState = textState.join("&");
      var url = "/popups?" + text;
      var pushStateUrl = "?" + textState;

      // window.history.pushState(null, null, pushStateUrl);

      var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
      xhr.onreadystatechange = text;
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(text);
      xhr.onload = function() {
         container.innerHTML = xhr.response;
         currentPopup.open();
      };
      xhr.onerror = function() {
         alert("error");
      };
   }
   initEvents() {
      var self = this;
      this.closeFn = () => {
         this.close();
      };
      
      window.addEventListener('click', function(e) {
         if (e.target == self.DOM.wrapper) {
            self.closeFn();
         }
      });
      this.DOM.close.addEventListener('click', this.closeFn);
   }
   open() {
      gsap.to(this.DOM.wrapper, {
         autoAlpha: 1,
         pointerEvents: "all",
      })
      copyLink(this.DOM.wrapper);
   }
   close() {
      gsap.to(this.DOM.wrapper, {
         autoAlpha: 0,
         pointerEvents: "none",
      })
   }
}