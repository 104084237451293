// const postType = document.querySelector('body').getAttribute('post_type');
// if (postType === 'vacature') {
//     tabSlider();
//     imageSlider();
//     playVideo();
// }

import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    Controller,
    Lazy,
    Mousewheel,
    Autoplay
} from '../../../config/node_modules/swiper';
Swiper.use([Navigation, Pagination, Scrollbar, Controller, Lazy, Mousewheel, Autoplay]);

const tabSlider = () => {
    var tabSliders =  document.querySelectorAll('.slider-container__tabs:not(.no-slider)');
	if (typeof(tabSliders) != 'undefined' && tabSliders != null) {
        tabSliders.forEach((tabSlider) => {
            var tab_attr_title = Array.from(tabSlider.querySelectorAll('.data-tab-slide')).map(function(x) {
                return x.getAttribute('data-tab-title');
            })

            var tabSwiper = new Swiper (tabSlider, {
                pagination: {
                    el: tabSlider.querySelector('.swiper-pagination'),
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<div class="' + className + '">' + (tab_attr_title[index]) + '</div>';
                    },
                },
                loop: false,
                speed: 500,
                spaceBetween: 100,
            });
        });
	}
} 


const imageSlider = () => {
	var imageSliders =  document.querySelectorAll('.images-slider');
	if (typeof(imageSliders) != 'undefined' && imageSliders != null) {
        imageSliders.forEach((imageSlider) => {
            var imageSwiper = new Swiper (imageSlider, {
                direction: 'horizontal',
                loop: true,
                speed: 600,
                roundLengths: true,
                centeredSlides: true,
                mousewheel: {
                    forceToAxis: true,
                    thresholdTime: 400,
                },
                breakpoints: {
                    0: {
                        spaceBetween: 30,
                    },
                    767: {
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 'auto',
                        spaceBetween: 40,
                    }
                },
            });
        });
	}
}

const playVideo = () => {
	let videos = document.querySelectorAll('.video-element__play');
	console.log(videos);
    if (videos) {
		for (var i = 0; i < videos.length; i++) {
			let thisVideo = videos[i];
			thisVideo.addEventListener('click', function() {
				let targetId = this.getAttribute('data-id');
				let targetSource = document.getElementById(targetId).getAttribute('video-src');
				
				console.log(targetId, targetSource);
				document.getElementById(targetId).setAttribute('src', targetSource);
				document.getElementById(targetId).parentElement.classList.add('video--loaded');
			})
		}
	}
}

export {
    tabSlider, imageSlider, playVideo
}