import {LottiePlayer} from '../../../config/node_modules/@lottiefiles/lottie-player';

export function lottiefiles(scope, done) {
    scope.querySelectorAll('.lottie').forEach(lottie => {
        // console.log(lottie);
        var player = lottie.querySelector('lottie-player');   
        // console.log(lottie.querySelector('lottie-player').src)

        player.load(lottie.querySelector('lottie-player').src); 
        player.play();
        player.stop();
        // console.log(lottie.getAttribute('data-state'))
        if (!done) {
            // console.log(lottie.getAttribute('data-state'));
            if (lottie.getAttribute('data-state') == 'hover') {
                lottie.closest('.button').addEventListener("mouseover", e => {
                    player.setDirection(1);
                    player.play();
                });
                lottie.closest('.button').addEventListener("mouseout", e => {
                    player.setDirection(-1);
                    player.play();
                }); 
            }
        } else {
            lottie.closest('.button').classList.add('done');
            player.setDirection(1);
            player.play();
            // setTimeout(() => {
            //     lottie.closest('.button').classList.remove('done');
            // }, 5000)
        }
        
    })
}