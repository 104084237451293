import { gsap } from "../../../config/node_modules/gsap";

function accordion() {
    var accordionElements = document.querySelectorAll('.plate--element__accordion');
    if (typeof(accordionElements) === 'undefined' || accordionElements === null || accordionElements.lenght === 0) return;

    accordionElements.forEach(accordionElement => {

        var groups = [...accordionElement.querySelectorAll(".accordion-item")];
        let currentIndex = null;
        let isAnimating = false;

        groups.forEach((e, i) => {
            const header = e
            const content = e.nextElementSibling;
            // console.log(content.offsetHeight);
            // console.log(i ? true : false);

            // const verticalBar = e.querySelector(".bar-2");

            const tl = gsap.timeline({paused: true});
            tl.addLabel("start");
            if (content.querySelector('.proces-items')) {
                tl.fromTo(content, {
                    height: 0,
                }, {
                    height: 200,
                    duration: 0.8, 
                    ease: "Expo.easeInOut",
                }, "start");
            } else {
                tl.fromTo(content, {
                    height: 0,
                }, {
                    height: "auto",
                    duration: 0.8, 
                    ease: "Expo.easeInOut",
                }, "start");
            }
           

            // Setting first tl (.accordion-active) to final progress
            // if (i === 0) {
            //     tl.progress(1);
            // }
            
            e.groupTimeline = tl;

            // i === 0 && e.classList.add("accordion-active");

            e.addEventListener("click", () => {
                // if(e.classList.contains("accordion-active")) return;
                if(isAnimating) return;
                
                if (currentIndex !== null) {
                    groups[currentIndex].classList.toggle("accordion-active");
                    if (currentIndex === i) {
                        console.log("currentIndex === i")
                        currentIndex = null;
                        return tl.reverse();
                    }
                    groups[currentIndex].groupTimeline.reverse();
                }
                
                e.classList.toggle("accordion-active");
                tl.play();
                currentIndex = i;
            });

            // e.addEventListener("click", () => {
            //     if (!e.classList.contains("accordion-active")) {
            //         // If not accordion-active, open and play timeline
            //         e.classList.add("accordion-active");
            //         tl.play();
            //     } else {
            //         // If accordion-active, reverse timeline
            //         e.classList.remove("accordion-active");
            //         tl.reverse();
            //     }
            // });
        });

        window.addEventListener("resize", () => {
            groups.forEach((e) => {
                if (e.classList.contains("accordion-active")) {
                    e.groupTimeline.progress(0);
                    e.groupTimeline.invalidate();
                    e.groupTimeline.progress(1);
                } else {
                    e.groupTimeline.invalidate();
                }
            });
        });


    });
    
}

export {
    accordion
}