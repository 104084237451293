var $ = require('../../../config/node_modules/jquery');
import { gsap } from '../../../config/node_modules/gsap';
$(document).ready(function () {

    var line = $('.usp-line');

    if(line.length === 0) return;

    gsap.defaults({
        ease: "Circ.easeInOut", 
    });
    var tl = gsap.timeline({
        onComplete: function () {
            tl.restart();
        }
    });

    var time = 2;
    var y = 140;

    tl
        .fromTo(line, {
            opacity: 0,
            y: y,
        }, {
            opacity: 1,
            y: 0,
            stagger: time,
            duration: 1,
        })

        .to(line, {
            delay: 2,
            opacity: 0,
            duration: 1,
            y: -y,
            stagger: time,
        }, "<");

    
});