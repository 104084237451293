import { gsap } from "../../../config/node_modules/gsap";
import ScrollTrigger from "../../../config/node_modules/gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const stickyNav = () => {
  var nav = document.querySelector("sticky-nav");
  var mediaQuery = window.matchMedia("(min-width: 1024px)");

  var navScroller = document.querySelector(".vacature-content"),
    navbar = document.querySelector(".post-header");

  var linkCounter = 0;
  if (
    typeof document.querySelectorAll("a[href*=\\#]") != "undefined" &&
    document.querySelectorAll("a[href*=\\#]") != null
  ) {
    document
      .querySelectorAll("a[href*=\\#]:not([href=\\#])")
      .forEach((el, index) => {
        if (el.hash) {
          var linkHash;
          var beginElement = hashMaker(el.hash),
            endElement,
            ScrollerId = el.hash.replace(/\D/g, "");

          if (
            typeof document.querySelectorAll("a[href*=\\#]:not([href=\\#])")[
              index + 1
            ] != "undefined" &&
            document.querySelectorAll("a[href*=\\#]:not([href=\\#])")[
              index + 1
            ] != null
          ) {
            endElement = hashMaker(
              document.querySelectorAll("a[href*=\\#]:not([href=\\#])")[index]
                .hash
            );
          } else {
            endElement = document.querySelector(".footer");
          }

          function hashMaker(h) {
            linkHash = document.querySelector(h);
            return linkHash;
          }

          console.log(el.hash, { beginElement }, { endElement });

          ScrollTrigger.create({
            trigger: beginElement,
            pin: false,
            markers: false
            
            
            
            ,
            start: "top 150px",
            endTrigger: endElement,
            // id: Math.floor(Math.random() * parseInt(ScrollerId)),
            onUpdate: () => {
              var activeElement, activeNav, activeNavWrapper;
              if (!mediaQuery.matches) {
                activeElement = document.querySelector(
                  "a.active[href*=\\#]:not([href=\\#])"
                );
                activeNav = activeElement.closest(".post-nav");
                activeNavWrapper = activeNav.closest(".sticky-nav-wrapper");

                gsap.to(activeNav, {
                  x: -Math.abs(activeElement.offsetLeft),
                });
                // console.log({activeElement}, {activeNav}, {activeNavWrapper}, activeElement.offsetLeft, activeNav.offsetWidth, activeNavWrapper.offsetWidth);
              }
              return;
            },
            onEnter: () => {
              document
                .querySelectorAll("a[href*=\\#]:not([href=\\#])")
                .forEach((item, index) => {
                  item.classList.remove("active");
                });
              el.classList.add("active");
            },
            onLeaveBack: () => {
              if (
                typeof document.querySelectorAll(
                  "a[href*=\\#]:not([href=\\#])"
                )[index - 1] != "undefined" &&
                document.querySelectorAll("a[href*=\\#]:not([href=\\#])")[
                  index - 1
                ] != null
              ) {
                el.classList.remove("active");
                document
                  .querySelectorAll("a[href*=\\#]:not([href=\\#])")
                  [index - 1].classList.add("active");
              }
            },
          });
        }
      });
  }

  // var End;
  // if(!mediaQuery.matches) {
  //     End = `${navScroller.offsetHeight + (window.innerHeight / 2)}`;
  // } else {
  //     End = `${navScroller.offsetHeight - (window.innerHeight / 2)}`;
  // }

  // setTimeout(() => {
  //     ScrollTrigger.create({
  //         // scroller: document.querySelector('#smooth-wrapper'),
  //         trigger: nav,
  //         pin: true,
  //         start: "top top",
  //         // markers: true,
  //         end: End,
  //         // endTrigger: document.querySelector('.footer--tray'),
  //         id: Math.floor(Math.random() * nav.id),
  //     });
  // }, 500)
};
