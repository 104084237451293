import { gsap } from '../../../config/node_modules/gsap';
import * as echarts from '../../../config/node_modules/echarts/core';
import { PieChart } from '../../../config/node_modules/echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    ToolboxComponent
} from '../../../config/node_modules/echarts/components';
import { LabelLayout, UniversalTransition } from '../../../config/node_modules/echarts/features';
import { SVGRenderer } from '../../../config/node_modules/echarts/renderers';

echarts.use([
    PieChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LabelLayout,
    UniversalTransition,
    SVGRenderer,
    ToolboxComponent    
]);

var chart = () => {
    document.querySelectorAll(".diagram").forEach((item) => {
       
        var myChart = echarts.init(item.querySelector('.chart'), null, { renderer: 'svg',
            width: 200,
            height: 200
        });

        var myChartJson = JSON.parse(item.querySelector("script").innerHTML);
        
        myChart.setOption({
            series: [
                {
                    type: 'pie',
                    color: myChartJson.colors,
                    data: myChartJson.data,
                    label: true, 
                }
            ],
        });
    
        var chartPies = item.querySelector('.chart').querySelectorAll('svg path'),
            chartPiesData = item.querySelectorAll('.diagram-content-item'); 
        
        chartPies.forEach((pie, index) => {

            pie.addEventListener("mouseover", e => {
                gsap.to(chartPies, {
                    opacity: .3
                }) 
                gsap.to(pie, {
                    opacity: 1
                })
                gsap.to(chartPiesData, {
                    autoAlpha: 0,
                    duration: 0,
                })
                gsap.to(chartPiesData[index], {
                    autoAlpha: 1,
                    duration: 0,
                });
            });
            pie.addEventListener("mouseout", e => {
                // gsap.to(chartPiesData[index], {
                //     autoAlpha: 0,
                // });  
               
            }); 
        })

        function getMaxHeight(className) {
            var max = 0;
            chartPiesData.forEach(
              function(el) {
                // console.info(Math.max(el.scrollHeight, max));
                if (el.scrollHeight > max) {
                  max = el.scrollHeight;
                }
              }
            );
            return max;
        }
          
        function setHeight(className, height) {
            chartPiesData.forEach(
                function(el) {
                    el.style.height = height+'px';
                }
            );
        }
          
        var max = getMaxHeight(chartPiesData);
        setHeight(chartPiesData, max);
        window.addEventListener('resize', function(event) {
            var max = getMaxHeight(chartPiesData);
            setHeight(chartPiesData, max);
        }, true);
    }); 
}

export {
    chart
}