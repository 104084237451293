import { gsap } from "../../../config/node_modules/gsap";

function proces() {
  var processElements = document.querySelectorAll(".proces");
  if (
    typeof processElements === "undefined" ||
    processElements === null ||
    processElements.lenght === 0
  )
    return;

  processElements.forEach((processElement) => {
    var procesItems = processElement.querySelectorAll(".proces-item");
    function resetItems() {
      procesItems.forEach((item) => {
        gsap.to(item.querySelector(".proces-item-bullet"), {
          backgroundColor: "#C1C1C1",
          duration: 0.5,
        });
        const tooltip = item.querySelector(".proces-item-tooltip");
        gsap.to(tooltip, { autoAlpha: 0, duration: 0.5 });
      });
    }

    const tl = gsap.timeline({
      onComplete: () => {
        setTimeout(() => {
          resetItems();
          tl.restart();
        }, 2000);
      },
      repeat: 0,
    });

    procesItems.forEach((item, index) => {
      tl.call(
        () => {
          const tooltip = item.querySelector(".proces-item-tooltip");
          gsap.to(item.querySelector(".proces-item-bullet"), {
            backgroundColor: "var(--primary)",
            duration: 0.5,
          });

          gsap.to(tooltip, { autoAlpha: 1, duration: 0.5 });

          gsap.to(processElement.querySelector(".proces-items-timeline-bar"), {
            scaleX:
              (1 /
                parseFloat(
                  processElement
                    .querySelector(".proces-items")
                    .getAttribute("data-size")
                )) *
              index,
            duration: 0.5,
          });

          if (index > 0) {
            const previousTooltip = procesItems[index - 1].querySelector(
              ".proces-item-tooltip"
            );
            gsap.to(previousTooltip, { autoAlpha: 0, duration: 0.5 });
          }
        },
        [],
        "+=2"
      );
    });
  });
}

export { proces };
