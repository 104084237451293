// var $ = require('../../../config/node_modules/jquery');
import $ from '../../../config/node_modules/jquery';

// window.$ = $;

import '../../../config/node_modules/chosen-js';
import { modaal } from '../../../config/node_modules/modaal';

// import * as SpriteSpin from '../../../config/node_modules/spritespin';

function isEmpty( el ){
    return !$.trim(el.html())
}

// 360 product view
//
$(document).ready(function () {

    setTimeout(function(){
        $('.modaal-noscroll *').mousedown(function(event) {
            switch (event.which) {
                case 3:
                    alert('Deze afbeelding is auteursrechtelijk beschermd en mag niet zonder toestemming van ' + $(location).attr('hostname')  + ' worden gebruikt.');
                    return false;
                }
            })
        }, 3000);

    // if ($('#product-360').length) {
    //     rotate360();
    // }

    $(".product-image .gallery").modaal({
        type: 'image'
    });
    
    $(".afbeeldings-galerij .gallery").modaal({
        type: 'image'
    });

    

    setInterval(function() {
        // ToggleActive()
    }, 300);

    productIndex()

});



$(".scroll-down-link").on("click", function (event) {
    event.preventDefault();
    var link = parseInt($('.scroll-down-link').height());
    var header = parseInt($('.navigation').height());
    let offset = header;
    console.log(offset);
    var lnk = $.attr(this, 'href').replace('/', '');
    console.log(lnk);
    $('html, body').animate({
        scrollTop: $(lnk).offset().top + -offset
    }, 300);
});


if (isEmpty($('.filter-tags'))) {
    $('.filter-tags').hide();
} else {
    $('.filter-tags').show();
}


function ToggleActive(){
    // var item_link = document.getElementsByClassName('swiper-pagination-bullet-active');
    var item_link = document.querySelector('.home-header .swiper-pagination-bullet-active');
    // console.log(item_link);
    var child_link = $(item_link).children('a').attr('href');
    var fulllink = $('.swiper-pagination .full-link-btn.link');
    $(fulllink).attr("href", child_link);
}


// Triangle hover in footer

$('#mandelo').hover(
    function () {
        $('#mandelo svg.text').fadeIn("fast");
    },
    function () {
        $('#mandelo svg.text').fadeOut("fast");
    }
);



$(function () {
    // Change url for product brochure download
    $(".change-brochure-price").on("change", function () {
        if (this.checked) {
            $("#download-brochure").attr("href", $(this).data("url"));
            $("#download-brochure-1").attr("href", $(this).data("url"));
        }
    });

    // Request the generation of the brochure
    $("#download-brochure, #download-brochure-1").on("click", function (e) {
        e.preventDefault();

        var url = $.trim($(this).attr("href")) + "&" + constructExtraQuery();
        var self = this;

        console.log();

        $(self).parent().parent().hide();
        $(self).parent().parent().prev().show();

        $.ajax({
            url: url,
            success: function (data, status) {
                pollForFileWithKey(data.file_key, $(self).data("poll-url"), $(self).data("retrieve-url"))
            },
            error: function (data, status) {
                console.log("Error: " + JSON.stringify(data));
            }
        });

        return false;
    });
    var button = $(".download-brochure-button")
    var enableCustomProductSelectorLink = $(".enable-custom-product-selector")
    var disableCustomProductSelectorLink = $(".disable-custom-product-selector")
    var customProductSelector = $(".custom-product-selector-container")
    var activeResult = $(".active-result")

    enableCustomProductSelectorLink.on("click", function(e) {
        e.preventDefault();
        $(".custom-product-selector").chosen("destroy")
        enableCustomProductSelectorLink.hide()
        disableCustomProductSelectorLink.show()
        customProductSelector.show()
        $(".custom-product-selector").chosen()

    });

    $('.custom-product-selector-container').on("click", function(e) {
        $(this).next().toggleClass('animation-download');
    });

    disableCustomProductSelectorLink.on("click", function(e) {
        e.preventDefault();
        enableCustomProductSelectorLink.show();
        $(this).next().toggleClass('animation-download');
        // disableCustomProductSelectorLink.hide()
        customProductSelector.hide();
    })

})


// $(document).ready(function () {

//     $(".single-product .product-header .product-image svg").on("click", function (e) {
//         e.preventDefault();
//         rotate360();
//     });

// });


/** Image Download */

// $('img, .image-inner, .image-inner, .product-image, .modaal-noscroll ' ).on('contextmenu', function() {
//     alert('Deze afbeelding is auteursrechtelijk beschermd en mag niet zonder toestemming van ' + $(location).attr('hostname')  + ' worden gebruikt.');
//     return false;
// }); 



function constructExtraQuery() {
    var queryParameters = {}
    var extraQuery;

    queryParameters["filterMainCategories"] = $.map($(".main-category-selector.active"), function (x, v) {
        return $(x).data("category-id")
    });

    var selectedProducts = $(".custom-product-selector").val();
    if (selectedProducts) {
        queryParameters["products"] = selectedProducts;
    }

    return extraQuery = $.param(queryParameters);
}

// Check if the brochure is already generated
function pollForFileWithKey(fileKey, pollUrlBase, retrieveUrlBase) {
    var pollUrl = pollUrlBase + "?file_key=" + fileKey;
    $.ajax({
        url: pollUrl,
        success: function (data, status) {
            if (data.file_ready) {
                window.location.href = retrieveUrlBase + "?file_key=" + fileKey;
                $(".download-setup").show();
                $(".download-progress").hide();

            } else {
                setTimeout(function () {
                    pollForFileWithKey(fileKey, pollUrlBase, retrieveUrlBase)
                }, 3000);
            }
        },
        error: function (data, status) {
            console.log("Error: " + JSON.stringify(data));
        }
    });


}

// Remove product from cart
$(".delete-product-from-cart").on("click", function () {
    var pid = $(this).data("product-id");
    var url = window.location.pathname + "?remove_product=" + pid;
    $(this).html("<p>Aan het verwijderen...</p>");
    var container = $(this).parents(".product-wrapper");
    $.ajax({
        url: url,
        success: function (data, status) {
            $(container).fadeOut(200);
        },
        error: function (data, status) {
            console.log("Error: " + data)
        }
    });
});

$('li.nav-icon.user').on('mouseover', function () {
    $('.login-block', this).addClass('open');
}).on('mouseout', function (e) {
    if (!$(e.target).is('input')) {
        $('.login-block', this).removeClass('open');
    }
});

$('.language-dropdown-btn').on('mouseover', function () {
    $('.language-dropdown', this).addClass('open');
}).on('mouseout', function (e) {
    if (!$(e.target).is('input')) {
        $('.language-dropdown', this).removeClass('open');
    }
});


// var accItems = document.querySelectorAll(".accordion:not(.accordion-item)");
// var i;
// var j;
// console.log(accItems);
// for (i = 0; i < accItems.length; i++) {
//     accItems[i].addEventListener("click", function () {
  
//         for (j = 0; j < accItems.length; j++) {
//             accItems[j].nextElementSibling.style.maxHeight = null;
//             var $this = accItems[j].nextElementSibling;
//             $($this).find('.check_prep').prop('checked', false);
//         }

//         var panel = this.nextElementSibling;


//         if ($(this).hasClass('active')) {
//             $(this).removeClass('active');
//             $(this).next('.panel').css('max-height', '0');
//         } else if ($(this)) {
//             $(this).addClass('active');
//             $(this).siblings().removeClass('active');
//             if (!$(this).hasClass('mobile')) {
//                 if ($(this).next('.panel').find('.check_prep').length === 0) {

//                     $(this).next('.panel').css('max-height', '0');
//                     $(this).addClass('no-border');
//                 }
//             }
//         }

//         if (panel.style.maxHeight) {
//             panel.style.maxHeight = null;
//         } else {
//             panel.style.maxHeight = panel.scrollHeight + "px";
//         }

//     });
// }

// $('.filter-suitable .suitable-item').on("click", function () {
//     $(this).toggleClass("active");
// });



if ($(window).width() <= 990) {
    $('.item-wrapper').addClass("active");

    var section = $(".section-container");

    section.each(function (i, element) {
        var padTop = parseInt($(this).css('padding-top'));
        var padBot = parseInt($(this).css('padding-bottom'));

        var newPadTop = padTop / 2;
        var newPadBot = padBot / 2;

        $(this).css({
            'padding-top': newPadTop + 'px'
        });
        $(this).css({
            'padding-bottom': newPadBot + 'px'
        });
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() >= 30) {
            $('.navigation').addClass('fixed-header');
        } else {
            $('.navigation').removeClass('fixed-header');
        }
    });

} else {

    $(document).on({
        mouseenter: function () {
            $(this).addClass("active");
        },
        mouseleave: function () {
            $(this).removeClass("active");
        }
    }, '.item-wrapper');



    $(window).scroll(function () {
        if ($(window).scrollTop() >= 40) {
            $('.navigation').addClass('fixed-header');
        } else {
            $('.navigation').removeClass('fixed-header');
        }
    });
}

if ($(window).width() >= 600) {
    $('#mandelo').hover(
        function () {
            $('#mandelo svg.text').fadeIn("fast");
        },
        function () {
            $('#mandelo svg.text').fadeOut("fast");
        }
    );
}


$(document).ready(function () {

    $(".search-toggler").on('click', function (e) {
        $(".top-bar-search-wrapper").toggle();
        $(".top-bar-search-wrapper input[type='text']").focus();
    });
    productIndex();

    /*let element = $( ".cats .label" );

    if ($(element).hasClass('.vries-cat')) {
        $(this).prevAll().css( "background-color", "red" );
    } else if ($(element).hasClass('.koel-cat')) {
        $(this).nextAll().css( "background-color", "blue" );
    }*/

    $('.navbar-toggler').click(function () {
        $('.navigation').toggleClass('menu-open');
        if ($('body').hasClass('lock-scroll')) {
            $('body').removeClass('lock-scroll');
        } else {
            $('body').addClass('lock-scroll');
        }
    });
});


$(".company-logo").each(function (i) {
    $('.referentie-inner-wrapper').height($('.referentie.active').outerHeight(true));

    $(this).on("click", function (e) {
        e.preventDefault();

        var parent = $('.referentie-inner-wrapper');
        var activeElement = $('.company-logo.active');
        var activeReference = $('.referentie.active');
        var newElement = $('.referentie' + i);

        $(activeElement).removeClass('active');
        $(activeReference).removeClass('active');
        $(this).addClass('active');
        $(newElement).addClass('active');
        $(parent).height($(newElement).outerHeight(true));
    });
});

$(document).ready(function () {
    
    const setActive = (el, active) => {
        const formField = el.parentNode;
        if (active) {
            formField.classList.add('form-field--is-active');
            formField.classList.remove('req')
        } else {
            formField.classList.remove('form-field--is-active');
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled');
        }
    };

    [].forEach.call(
        document.querySelectorAll('.form-field__label, .form-field__input, .form-field__textarea'),
        el => {
            el.onblur = () => {
                setActive(el, false);
            };
            el.onfocus = () => {
                setActive(el, true);
            };
        });
});

$("#beurs_check").click(function () {
    if ($(this).prop('checked') === true) {
        $('.form-fields.beurzen').css('display', 'flex');
        // $('.form-fields.beurzen .input-field input').prop('required', true);
    } else {
        $('.form-fields.beurzen').hide();
        // $('.form-fields.beurzen .input-field input').prop('required', false);
    }
});


$(".extra-field .checkbox input").click(function () {
    if ($(this).prop('checked') === true) {
        $(this).parents('.extra-fields').find('.form-fields-dependent').show();
    } else {
        $(this).parents('.extra-fields').find('.form-fields-dependent').hide();
        $(this).parents('.extra-fields').find('.form-fields-dependent').find('input').val('');
        $(this).parents('.extra-fields').find('.form-fields-dependent').find('textarea').val('');
        $(this).parents('.extra-fields').find('.form-fields-dependent').find('option').attr('selected', false);
        $(this).parents('.extra-fields').find('.form-fields-dependent').find('.form-field--is-filled').removeClass('form-field--is-filled');
    }
});




// Hide CTA-bar on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 50;
var ctaBarHeight = $('.cta-bar-wrapper').outerHeight();

$(window).scroll(function (event) {
    didScroll = true;
});

setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function productIndex(){
    var proHeader = $('.index-header-left').height();
    var proHeaderCont = $('.titel-met-intro').height();
    var proIndex = $('.post-overview-wrapper');

    var proIndexOffset = (parseInt(proHeader) - parseInt(proHeaderCont)) - 75;

    $(proIndex).css('margin-top', -proIndexOffset );
}

function hasScrolled() {

    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-down.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > ctaBarHeight) {
        // Scroll Down
        $('.cta-bar-wrapper').removeClass('cta-up').addClass('cta-down');
    } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
            $('.cta-bar-wrapper').removeClass('cta-down').addClass('cta-up');
        }
    }

    lastScrollTop = st;
}

/*IMAGE GALLERY GRID*/

$(window).on('load resize', function () {

    setTimeout(function(){
        $('.modaal-noscroll *').mousedown(function(event) {
            switch (event.which) {
                case 3:
                    alert('Deze afbeelding is auteursrechtelijk beschermd en mag niet zonder toestemming van ' + $(location).attr('hostname')  + ' worden gebruikt.');
                    return false;
                }
            })
        }, 3000);
    

    // $('.grid .galerij-image').each(function (i, element) {
    //     let itemHeight = $(this).outerHeight(true);

    //     resizeGridItem(element, itemHeight);
    // });

    productIndex();
});

// setInterval(function(){
//     $('.grid .galerij-image').each(function (i, element) {
//         let itemHeight = $(this).outerHeight(true);
        
//         resizeGridItem(element, itemHeight);
//     });

// },300);

// function resizeGridItem(element, itemHeight) {
//     let grid = document.getElementsByClassName("grid")[0];
//     let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
//     let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
//     let rowSpan = Math.ceil((itemHeight + rowGap) / (rowHeight + rowGap));
//     element.style.gridRowEnd = "span " + rowSpan;
// }
