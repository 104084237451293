
var $ = require("../../../config/node_modules/jquery");
import { lottiefiles } from './lottie';

const debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const loadScript = function(url, callback) {
    var head = document.head;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    if(callback !== false){
        script.onreadystatechange = callback;
        script.onload = callback;
    }
    
    // Fire the loading
    head.appendChild(script);
}

async function copyLink(container) {
    $('.copy-link').on('click', function(e) {
        e.preventDefault();
        var href = $(this).attr('href');
        copyToClipboard(href);
        lottiefiles(document, true);
    });

    const copyToClipboard = (href) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(href).select();
        document.execCommand("copy");
        $temp.remove();
    }
}


export {
    debounce, loadScript, copyLink
}