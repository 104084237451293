import { gsap } from "../../../config/node_modules/gsap";

async function anchorScroll() {
  var anchorLinks = document.querySelectorAll('a[href^="#"]');
  console.log(anchorLinks);
  if (typeof anchorLinks === "undefined" || anchorLinks === null) return;

  gsap.utils.toArray(anchorLinks).forEach(function (button, i) {
    button.addEventListener("click", (e) => {
      var id = e.target.getAttribute("href");
      var targetElement = document.querySelector(id);
      var targetElement = document.querySelector(id);
        
      if (targetElement) {
        const topPos =
          targetElement.getBoundingClientRect().top + window.scrollY - 200;

        window.scrollTo({
          top: topPos,
          left: 0,
          behavior: "smooth",
        });

        e.preventDefault();
      }
    });
  });
}

export { anchorScroll };
