
import '../../config/node_modules/regenerator-runtime/runtime';
/* import './scripts/lib/chosen.jquery.min'; */
/* import './scripts/lib/flickity.pkgd';  */
import './scripts/lib/underscore';
import { popup } from './scripts/popup';
popup();

import './scripts/posts-filter';
import {tabSlider, imageSlider, playVideo} from './scripts/schedule';
tabSlider();
imageSlider();
playVideo();
import {proces} from './scripts/proces';
proces();
/* import './scripts/modaal'; */ 
import { gallerySlider, headerSlider, homeSlider } from './scripts/swiper';
homeSlider();
headerSlider();
gallerySlider();
import {accordion } from './scripts/accordion';
accordion();
import './scripts/flickity';
import './scripts/script';
import './scripts/usp_line';
import {chart} from './scripts/chart';
chart();
import { stickyNav } from './scripts/stickyNav';
stickyNav();
import './scripts/spritespin';
import { anchorScroll } from './scripts/anchorscroll';
anchorScroll();
import './styles/style';

